import Typography from "@mui/material/Typography";
import DoneIcon from '@mui/icons-material/Done';
import { useSelector } from 'react-redux';
import dynamic from 'next/dynamic';

const FooterAccordion = dynamic(() => import('./FooterAccordion'));

const fontSize = {
    fontSize: '14px'
}
const FooterPromise = ({type}) => {
    const customer_service_score = useSelector(state => state.globalReducer.customer_service_score);
    const template_settings = useSelector(state => state.globalReducer.template_settings);

    return (
        type == "desktop" ? (
            <>
                <Typography className="f-size_3 uppercase font-regular mb-3" component="h5">We Promise To</Typography>
                <ul className="font-light">
                    
                    <li style={fontSize} className="mb-4 d-flex"> <DoneIcon className="mr-1" color="primary" /> Provide instant quotations both online and offline </li>
                    <li style={fontSize} className="mb-4 d-flex"> <DoneIcon className="mr-1" color="primary" /> Deliver and collect within your chosen time frames</li>
                    <li style={fontSize} className="mb-4 d-flex"> <DoneIcon className="mr-1" color="primary" /> Provide a friendly and efficient service</li>
                    {customer_service_score.score > 90 && <li style={fontSize} className="mb-4 d-flex"> <DoneIcon className="mr-1" color="primary" /> Display our live customer satisfaction score {customer_service_score.score}% </li>}
                </ul>
            </>

        ) : (
            <FooterAccordion classes="fw-bold font-regular" title="We Promise To" accordionType="promises" type="footer">
                <ul className="font-light">
                    <li style={fontSize} className="mb-4 d-flex">
                        <DoneIcon color="primary" className="mr-2" />
                        Provide instant quotations both online and offline
                    </li>
                    <li style={fontSize} className="mb-4 d-flex">
                        <DoneIcon color="primary" className="mr-2" />
                        Deliver and collect within your chosen time frames
                    </li>
                    <li style={fontSize} className="mb-4 d-flex">
                        <DoneIcon color="primary" className="mr-2" />
                        Provide a friendly and efficient service
                    </li>
                    {customer_service_score.score > 90 && <li style={fontSize} className="mb-4 d-flex">
                        <DoneIcon color="primary" className="mr-2" />
                        Display our live customer satisfaction score {customer_service_score.score}% 
                    </li>}
                </ul>
            </FooterAccordion>
   
        )
    )
}

export default FooterPromise